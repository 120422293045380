// @flow

export type Action =
  | { type: 'SET_WORD_INDEX', payload: { wordIndex: number } }
  | { type: 'FIREBASE_AUTH_STATE_CHANGED', payload: { user: $npm$firebase$auth$User } };

export const actionCreators = {
  setWordIndex: (wordIndex: number) => ({ type: 'SET_WORD_INDEX', payload: { wordIndex } }),
  firebaseAuthStateChanged: (user: number) => ({
    type: 'FIREBASE_AUTH_STATE_CHANGED',
    payload: { user },
  }),
};

// @flow

import * as React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import without from 'lodash/without';

import defaultWordIds from '../../data/wordIds.json';

import CardView from './CardView';

type Props = {
  firebase: any,
  // Redux
  dispatch: any,
  profile: any,
};

const clampToCircle = (index, steps) => {
  while (index < 0) index += steps;
  return index % steps;
};

class CardsPage extends React.PureComponent<Props, void> {
  _onPreviousClick = () => {
    const { firebase, profile } = this.props;
    const { wordIndex = 0, wordIds = [], checkedWordIds = [] } = profile;

    let nextIndex = null;
    for (let i = 1; i < wordIds.length; i += 1) {
      const index = clampToCircle(wordIndex - i, wordIds.length);
      const wordId = wordIds[index];
      if (checkedWordIds.includes(wordId)) continue;
      nextIndex = index;
      break;
    }

    firebase.updateProfile({ wordIndex: nextIndex });
  };

  _onNextClick = () => {
    const { firebase, profile } = this.props;
    const { wordIndex = 0, wordIds = [], checkedWordIds = [] } = profile;

    let nextIndex = null;
    for (let i = 1; i < wordIds.length; i += 1) {
      const index = clampToCircle(wordIndex + i, wordIds.length);
      const wordId = wordIds[index];
      if (checkedWordIds.includes(wordId)) continue;
      nextIndex = index;
      break;
    }

    firebase.updateProfile({ wordIndex: nextIndex });
  };

  _checkWord = (wordId: string) => {
    const { firebase, profile } = this.props;
    const { checkedWordIds = [] } = profile;

    if (checkedWordIds.includes(wordId)) return;

    firebase.updateProfile({
      checkedWordIds: [wordId, ...checkedWordIds],
    });
  };

  _uncheckWord = (wordId: string) => {
    const { firebase, profile } = this.props;
    const { checkedWordIds = [] } = profile;

    if (!checkedWordIds.includes(wordId)) return;

    firebase.updateProfile({
      checkedWordIds: without(checkedWordIds, wordId),
    });
  };

  _onCheckClick = () => {
    const { profile } = this.props;
    const { wordIndex = 0, wordIds = [] } = profile;

    const wordId = wordIds[wordIndex];
    this._checkWord(wordId);
  };

  _onUncheckClick = () => {
    const { profile } = this.props;
    const { wordIndex = 0, wordIds = [] } = profile;

    const wordId = wordIds[wordIndex];
    this._uncheckWord(wordId);
  };

  componentDidUpdate() {
    const { firebase, profile } = this.props;

    if (!profile.isLoaded) return;

    if (!profile.checkedWordIds) {
      firebase.updateProfile({ checkedWordIds: [] });
    }

    if (!profile.wordIds) {
      firebase.updateProfile({ wordIds: defaultWordIds });
    }
  }

  render() {
    const { profile } = this.props;

    if (!profile.isLoaded) return null;

    const { wordIndex = 0, wordIds = [], checkedWordIds = [] } = profile;

    const wordId = wordIds[wordIndex];
    const isChecked = checkedWordIds.includes(wordId);

    return (
      <Container>
        {`${checkedWordIds.length}/${wordIds.length - checkedWordIds.length}/${
          defaultWordIds.length
        }`}
        <CardView
          key={wordId}
          wordId={wordId}
          isChecked={isChecked}
          onPreviousClick={this._onPreviousClick}
          onNextClick={this._onNextClick}
          onCheckClick={this._onCheckClick}
          onUncheckClick={this._onUncheckClick}
        />
        {checkedWordIds.map((wordId) => (
          <CardView
            key={wordId}
            wordId={wordId}
            isChecked={true}
            onCheckClick={() => this._checkWord(wordId)}
            onUncheckClick={() => this._uncheckWord(wordId)}
          />
        ))}
      </Container>
    );
  }
}

export default connect(({ firebase: { profile } }) => ({ profile }))(firebaseConnect()(CardsPage));

// @flow

import * as React from 'react';
import { Navbar, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <Navbar className="my-5">
      <Nav className="justify-content-center w-100">
        <NavItem>
          <NavLink tag={Link} to="/privacy">
            Privacy Policy
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to="/tos">
            Terms of Service
          </NavLink>
        </NavItem>
      </Nav>
    </Navbar>
  );
}

// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';

type Props = {
  firebase: $npm$firebase$App,
  auth: any,
  children: React.Node,
};

class UserProvider extends React.PureComponent<Props, void> {
  componentDidUpdate() {
    const { auth, firebase } = this.props;

    if (auth.isLoaded && auth.isEmpty) {
      firebase.auth().signInAnonymously();
    }
  }

  render() {
    const { auth, children } = this.props;

    console.log(this.props);

    if (!auth.isLoaded || auth.isEmpty) return null;

    return children;
  }
}

export default connect(({ firebase: { auth } }) => ({ auth }))(firebaseConnect()(UserProvider));

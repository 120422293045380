// @flow

import * as React from 'react';
import { Container, Row, Col, Alert } from 'reactstrap';

import NavigationBar from './NavigationBar';
import Footer from './Footer';

type Props = {
  children: React.Node,
};

export default function AppFrame(props: Props) {
  const { children } = props;

  return (
    <>
      <Alert color="danger rounded-0">Do not use this website!</Alert>
      <Container fluid className="mb-3">
        <Row>
          <Col>
            <NavigationBar />
          </Col>
        </Row>
      </Container>
      {children}
      <Container fluid>
        <Row>
          <Col>
            <Footer />
          </Col>
        </Row>
      </Container>
    </>
  );
}

// @flow

import * as React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';

type Props = {
  wordId: string,
  isChecked: boolean,
  onCheckClick: Function,
  onUncheckClick: Function,
  onPreviousClick?: Function,
  onNextClick?: Function,

  // Redux
  word: ?any,
};

type State = {
  explanationShown: boolean,
};

class CardView extends React.PureComponent<Props, State> {
  state = {
    explanationShown: false,
  };

  _onShowExplanationToggleClick = () => {
    const { explanationShown } = this.state;
    this.setState({ explanationShown: !explanationShown });
  };

  render() {
    const {
      word,
      isChecked,
      onUncheckClick,
      onCheckClick,
      onPreviousClick,
      onNextClick,
    } = this.props;
    const { explanationShown } = this.state;

    return (
      <Row>
        <Col className="my-2" style={{ minHeight: '200px' }}>
          <div
            className="border rounded"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'stretch',
              height: '100%',
              width: '100%',
            }}
          >
            <div className="p-3" style={{ flex: 1 }}>
              <h3>{word && word.word}</h3>
              <p className="lead" style={{ flex: 1 }}>
                {word && explanationShown ? word.explanation : null}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              {onPreviousClick && <Button onClick={onPreviousClick}>Previous</Button>}
              <Button onClick={this._onShowExplanationToggleClick}>
                {explanationShown ? 'Hide' : 'Show'}
              </Button>
              {isChecked ? (
                <Button onClick={onUncheckClick} color="warning">
                  <FontAwesomeIcon icon={faStar} /> Checked!
                </Button>
              ) : (
                <Button onClick={onCheckClick} color="primary">
                  <FontAwesomeIcon icon={faStarRegular} /> Check
                </Button>
              )}
              {onNextClick && <Button onClick={onNextClick}>Next</Button>}
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default compose(
  firestoreConnect((props) => [{ collection: 'words', doc: props.wordId }]),
  connect(({ firestore: { data } }, props) => ({
    word: data.words ? data.words[props.wordId] : null,
  })),
)(CardView);

// @flow

import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import './environment/firebase';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';
// import * as serviceWorker from './serviceWorker';

const reactRootElement = document.getElementById('root');
if (!reactRootElement) {
  throw new Error('React root element not found');
}

ReactDOM.render(<App />, reactRootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();

// @flow

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

export default firebase;

// Initialize Firebase
const config = {
  apiKey: 'AIzaSyDfGXhbpdCexXwwlfp4nd_XWvYSvPw5nm0',
  authDomain: 'gradrx-1337.firebaseapp.com',
  databaseURL: 'https://gradrx-1337.firebaseio.com',
  projectId: 'gradrx-1337',
  storageBucket: '',
  messagingSenderId: '407929414021',
};
firebase.initializeApp(config);

// Initialize Cloud Firestore through Firebase
export const db = firebase.firestore();

// Disable deprecated features
db.settings({
  timestampsInSnapshots: true,
});

window.db = db;

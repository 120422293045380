// @flow

import * as React from 'react';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';

type Props = {
  // Redux
  auth: $npm$firebase$auth$User,
};

function NavigationBar(props: Props) {
  const { auth } = props;

  return (
    <Navbar>
      <NavbarBrand tag={Link} to="/">
        GradRx
      </NavbarBrand>
      <Nav>
        <NavItem>
          <NavLink tag={Link} to="/cards">
            <FontAwesomeIcon icon={faCreditCard} className="mr-1" />
            Cards
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to="/profile" className="d-flex align-items-center">
            {auth.isAnonymous && 'Login'}
            {!auth.isAnonymous && (
              <>
                <img
                  className="mr-1 border"
                  src={auth.photoURL}
                  alt={auth.displayName}
                  style={{
                    borderRadius: '4px',
                    width: '24px',
                    height: '24px',
                  }}
                />
                <span>Profile</span>
              </>
            )}
          </NavLink>
        </NavItem>
      </Nav>
    </Navbar>
  );
}

export default connect(({ firebase: { auth } }) => ({
  auth,
}))(NavigationBar);

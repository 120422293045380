// @flow

import { createStore, applyMiddleware, compose, combineReducers, bindActionCreators } from 'redux';
import { createLogger } from 'redux-logger';
import { reactReduxFirebase, firebaseReducer } from 'react-redux-firebase';
import { reduxFirestore, firestoreReducer } from 'redux-firestore';

import firebase from '../environment/firebase';

import appReducer from './appReducer';
import { type State } from './state';
import { actionCreators } from './actions';

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
};

export default function createApplicationStore(/* initialState?: State */) {
  const logger = createLogger({
    predicate: () => true,
    collapsed: true,
    duration: true,
  });

  // Firebase
  const createStoreWithFirebase = compose(
    reduxFirestore(firebase),
    reactReduxFirebase(firebase, rrfConfig),
  )(createStore);

  // Add firebase and firestore to reducers
  const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer,
  });

  // const store = createStore(appReducer, initialState, applyMiddleware(logger));
  const initialState = {
    firebase: {},
    firestore: {},
  };
  const store = createStoreWithFirebase(rootReducer, initialState, applyMiddleware(logger));

  const boundActionCreators = bindActionCreators(actionCreators, store.dispatch);
  Object.assign(store.dispatch, boundActionCreators);

  return store;
}

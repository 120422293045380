// @flow

import * as React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { firebaseConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';

type Props = {
  firebase: any,
  // Redux
  dispatch: any,
  // firebaseUser: $npm$firebase$auth$User | null,
  auth: any,
  profile: any,
};

class ProfilePage extends React.PureComponent<Props, void> {
  _onLoginClick = () => {
    const { firebase } = this.props;

    const provider = new firebase.auth.GithubAuthProvider();
    // provider.addScope('public_repo');
    provider.setCustomParameters({
      allow_signup: 'false',
    });

    firebase.auth().signInWithPopup(provider);
  };

  _onFacebookLoginClick = () => {
    const { firebase } = this.props;

    const provider = new firebase.auth.FacebookAuthProvider();

    firebase.auth().signInWithPopup(provider);
  };

  _onLogoutClick = () => {
    const { firebase } = this.props;

    firebase.auth().signOut();
  };

  render() {
    const { auth } = this.props;

    if (!auth.isLoaded) return null;

    if (auth.isAnonymous) {
      return (
        <Container>
          <Row>
            <Col className="d-flex flex-column justify-content-center">
              <Button color="primary" onClick={this._onLoginClick}>
                Login with GitHub
              </Button>
              <Button className="mt-3" color="primary" onClick={this._onFacebookLoginClick}>
                Login with Facebook
              </Button>
            </Col>
          </Row>
        </Container>
      );
    }

    return (
      <Container>
        <Row>
          <Col>
            <div className="d-flex flex-column align-items-center">
              <img
                className="mb-3"
                src={auth.photoURL}
                alt={auth.displayName}
                style={{
                  width: '40px',
                  height: '40px',
                }}
              />
              <h5>{auth.displayName}</h5>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col className="d-flex justify-content-center">
            <Button color="primary" onClick={this._onLogoutClick}>
              Logout
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connect(({ firebase: { auth } }) => ({
  auth,
}))(firebaseConnect()(ProfilePage));

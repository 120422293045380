// @flow

import * as React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import firebase from 'firebase/app';

import { createApplicationStore } from '../../store';
import AppFrame from '../AppFrame';
import ProfilePage from '../ProfilePage';
import CardsPage from '../CardsPage';

import UserProvider from './UserProvider';

// function getPersistedData() {
//   const stateJson = localStorage.getItem('stateJson');
//   if (!stateJson) {
//     return {};
//   }
//   return JSON.parse(stateJson);
// }

type State = {
  // userReady: false,
};

export default class App extends React.PureComponent<{}, State> {
  state = {
    // userReady: false,
  };

  store = createApplicationStore();

  componentDidMount() {
    const { store } = this;

    const { firebase } = store.getState();

    console.log('fb', firebase);

    // firebase.auth().
    // console.log('cu', firebase.auth().currentUser);
    // firebase.auth().signInAnonymously()
    // const { store } = this;
    // firebase.auth().onAuthStateChanged(store.dispatch.firebaseAuthStateChanged);
    // store.subscribe(() => {
    //   const state = store.getState();
    //   localStorage.setItem('stateJson', JSON.stringify(state));
    // });
  }

  render() {
    // const { userReady } = this.state;
    const { store } = this;

    // if (!userReady) return null;

    return (
      <Provider store={store}>
        <UserProvider>
          <BrowserRouter>
            <AppFrame>
              <Route exact path="/" component={() => 'Home'} />
              <Route path="/cards" component={CardsPage} />
              <Route path="/profile" component={ProfilePage} />
              <Route path="/privacy" component={() => 'Privacy'} />
              <Route path="/tos" component={() => 'Terms of Service'} />
            </AppFrame>
          </BrowserRouter>
        </UserProvider>
      </Provider>
    );
  }
}
